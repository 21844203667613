/*########################################
/#####RESET ELEMENTS
########################################*/
* {
  margin: 0; padding: 0; box-sizing: border-box;
}
ul {
  list-style: none; list-style-type: none;
}
ul li {
  float: left;
}
img, fieldset {
  border: none;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal; margin-bottom: 10px;
}
/*########################################
/#####FONT FILES
########################################*/
@font-face {
    font-family: 'Metropolis';
    src: url('font-files/metropolis-thin-webfont.woff2') format('woff2'),
         url('font-files/metropolis-thin-webfont.woff') format('woff');
    font-weight: 200; font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('font-files/metropolis-light-webfont.woff2') format('woff2'),
       url('font-files/metropolis-light-webfont.woff') format('woff');
  font-weight: 300; font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('font-files/metropolis-regular-webfont.woff2') format('woff2'),
       url('font-files/metropolis-regular-webfont.woff') format('woff');
  font-weight: 400; font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('font-files/metropolis-semibold-webfont.woff2') format('woff2'),
       url('font-files/metropolis-semibold-webfont.woff') format('woff');
  font-weight: 500; font-style: normal;
}
@font-face {
  font-family: 'Metropolis';
  src: url('font-files/metropolis-bold-webfont.woff2') format('woff2'),
       url('font-files/metropolis-bold-webfont.woff') format('woff');
  font-weight: 600; font-style: normal;
}

/*########################################
/#####MAIN ELEMENTS
########################################*/
body {
  background-color: #0F0E1D; font-family: 'Metropolis';
  font-weight: 300; font-size: 14px; color: #FFF; line-height: 1.6em;
}
h1 {
  color: #44DBF1; font-size: 12px; text-transform: uppercase;
  line-height: 1.2em;
}
h2 {
  font-weight: 600; font-size: 32px;
  i {
    font-weight: 300; font-size: 18px;
  }
}
h3 {
  font-size: 10px; text-transform: uppercase; margin-bottom: 0; 
  line-height: 1.3em; font-weight: 500; color: #df7c41;
}
p {
  float: left; margin-bottom: 10px; width: 100%;
}
.content {
  width: 100%; max-width: 1024px; margin: auto; padding: 0 30px;
}
.description {
  white-space: pre-line; margin-bottom: 15px;
  float: left;
}