#home {
	float: left; width: 100%; height: 90vh;
	display: flex; align-items: center; justify-content: center;
	background: url('../images/background.jpg') center bottom no-repeat;
	background-size: cover; position: relative;
	&::after {
		position: absolute; left: 0px; top: 0px; right: 0px; bottom: 0px;
		background: rgb(16 14 29 / 22%); content: '';
	}
	.logo {
		position: relative; z-index: 5000;
	}
	.logo-mobile {
		position: relative; z-index: 5000; display: none;
	}
}

#about {
	display: flex; padding: 40px 30px; align-items: center;
	.biography {
		width: 50%; padding-right: 30px;
	}
	.wall {
		display: grid; grid-gap: 10px; align-items: center;
		grid-template-columns: repeat(2, auto);
		.frame {
			width: 265px; height: 340px;
			background-size: contain; background-repeat: no-repeat;
			background-position: center;
			&.frame-1 {
				background-image: url('../images/frame-2.png');
			}
			&.frame-2 {
				background-image: url('../images/frame-4.png');
				position: relative;
				content: ""; background-image: url('../images/frame.gif');
				width: 250px; height: 325px; background-size: 275px auto;
				background-position: center; border: 18px solid #FFF;
				margin-left: 7px;
			}
			&.frame-3 {
				background-image: url('../images/frame-3.png');
			}
		}
		.right {
			display: flex; flex-direction: column; grid-gap: 0px;
		}
	}
}

@keyframes animateScale {
  0% { transform: scale(1); }
  100% { transform: scale(3); }
}

#projects {
	width: 100%; float: left; height: 400vh;
	.projects-sticky {
		position: sticky; top: 0; display: flex; height: 100vh; 
		alignItems: center; overflow: hidden; background: #1b1a2e;
	}
	.projects-wrapper {
		width: 100%; height: 100vh; display: flex;
		max-height: -webkit-fill-available;
		&.animated {
			transition: all 0.5s ease; -webkit-transition: all 0.5s ease;
			.project.active {
				animation: animateScale 0.5s ease 0.5s forwards;
			}
		}
		.project {
			width: 500px; height: 100vh; flex: 0 0 500px; 
			position: relative; overflow: hidden; display: flex;
			align-items: center; justify-content: center;
			cursor: pointer; max-height: -webkit-fill-available;
			&.active {
				width: 100%; flex: 0 0 100%; 
			}
			.project-image {
				position: absolute; left: 0px; right: 0px;
				top: 0px; bottom: 0px; background-size: cover;
				background-position: center; transition: all 0.5s ease; 
				-webkit-transition: all 0.5s ease;
				&::before {
					content: ''; position: absolute; left: 0px; right: 0px;
					top: 0px; bottom: 0px; background-color: #00000096;
					transition: all 0.5s ease;  -webkit-transition: all 0.5s ease;
				}
			}
			.title {
				position: relative; z-index: 5; text-align: center;
				h3 {
					margin-bottom: 7px; font-size: 12px;
				}
				h2 {
					text-transform: uppercase;
					line-height: 1em;
				}
			}
			&:hover {
				.project-image {
					transform: scale(1.1);
					&::before { opacity: 0.5; }
				}
			}
		}
	}
}

@keyframes animateProject {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

#project-details {
	position: fixed; background: #000; left: 0; right: 0; top: 0; 
	bottom: 0; z-index: 500000; display: flex; align-items: center;
	justify-content: center; opacity: 0;
	animation: animateProject 0.5s ease 0.5s forwards;
	.btn-close {
		position: fixed; width: 40px; height: 40px; top: 20px; right: 20px; 
		background-color: #df7c41; z-index: 5000; cursor: pointer;
		background-image: url('../images/btn-x.svg');
		background-position: center; background-repeat: no-repeat;
		transition: all 0.5s ease;  -webkit-transition: all 0.5s ease;
		&:hover {
			background-color: #000000;
		}
	}
	.btn-next {
		position: absolute; width: 50px; height: 50px; top: calc(50% - 25px); right: 20px; 
		background-color: white; z-index: 5000; cursor: pointer;
		background-image: url('../images/btn-next.svg');
		background-position: left 12px center; background-repeat: no-repeat;
		transition: all 0.5s ease;  -webkit-transition: all 0.5s ease;
		&:hover {
			background-position: left 17px center; background-color: #dafaff;
		}
	}
	.btn-previous {
		position: absolute; width: 50px; height: 50px; top: calc(50% - 25px); left: 20px; 
		background-color: white; z-index: 5000; cursor: pointer;
		background-image: url('../images/btn-previous.svg');
		background-position: right 12px center; background-repeat: no-repeat;
		transition: all 0.5s ease;  -webkit-transition: all 0.5s ease;
		&:hover {
			background-position: right 17px center; background-color: #dafaff;
		}
	}
	.project-wrapper {
		width: 100%; float: left; display: flex; flex-direction: column;
		height: 100%;
		.project-gallery {
			width: 100%; height: 100%; position: relative;
			background: url('../images/loader.gif') center center no-repeat;
			background-size: 35px;
			.url-project {
				position: absolute; right: 20px; bottom: 20px; background: #000; color: #FFF; 
				padding: 5px 15px; font-size: 11px; text-decoration: none; 
				text-transform: uppercase; font-weight: 500; z-index: 50000;
				transition: all 0.5s ease;  -webkit-transition: all 0.5s ease;
				&:hover {
					background: #df7c41;
				}
			}
		}
		video {
		  object-fit: cover; height: 100%; width: 100%;
		  position: absolute; top: 0; left: 0;
		}
		.picture {
			position: absolute; left: 0px; right: 0px; top: 0px;
			bottom: 0px; background-size: cover; background-position: center;
		}
		.project-description {
			width: 100%; background: #000;
			display: flex; border-top: 1px solid #ffffff91;
			span {
				max-width: 820px; float: left;
			}
			.row {
				width: 100%; padding: 25px 40px; display: flex;
				flex-direction: column; justify-content: center;
				ul.skills {
					float: left; width: 100%; padding-left: 0px;
					white-space: nowrap;
					li {
						width: 100%; float: left; padding-left: 20px; 
						position: relative;
						&::before {
							content: ''; position: absolute; left: 0px;
							width: 20px; height: 20px; background: url('../images/check.svg') left center no-repeat;
							background-size: auto 13px;
						}
					}
				}
				&.last {
					width: auto; padding: 40px; background: #1f1f1f; display: flex; 
					flex-direction: column; justify-content: center;
				}
			}
			h2 {
				text-transform: uppercase; font-size: 22px;
				margin-bottom: 5px;
			}
			h3 {
				margin-bottom: 3px;
			}
		}
	}
}

#clients {
	padding: 140px 0; text-align: center; float: left; width: 100%;
	.clients-list {
		display: grid; grid-template-columns: repeat(8, auto);
		grid-gap: 1px; margin-top: 20px;
		.client {
			padding-top: 100%; height: 100%; background: #ffffff0d;
			background-size: 100px; background-repeat: no-repeat;
			background-position: center;
		}
	}
}

#contact {
    background: url('../images/map.svg') right -250px top 30px no-repeat;
    background-size: 1320px auto; min-height: 800px; position: relative;
    width: 100%; float: left; display: flex;
    .description {
    	width: 100%;
    }
    a {
    	color: #FFF; padding: 2px 0 2px 35px; margin-bottom: 5px;
    	float: left; width: 100%;
    	&:hover {
    		color: #44DBF1;
    	}
    	&.ico-mail {
    		background: url('../images/ico-mail.svg') left center no-repeat;
    	}
    	&.ico-linkedin {
    		background: url('../images/ico-linkedin.svg') left center no-repeat;
    	}
    }
}

@media screen and (max-width: 960px) {
	#about .wall .frame {
		width: 125px; height: 165px;
	}
	#about .biography {
		width: auto;
	}
	#clients .clients-list {
		grid-template-columns: repeat(6, auto);
	}
	#project-details .project-wrapper .project-description {
		font-size: 12px; line-height: 1.5em;
	}
}

@media screen and (max-width: 680px) {
	#home .logo img {
		width: 160px;
	}
	#home {
		background-image: url('../images/frame.gif'); position: relative;
		&::after {
			content: ''; position: absolute; left: 0px;
			right: 0px; top: 0px; bottom: 0px;
	 		background: #0f0e1dd4;
		}
	}
	#about {
		flex-direction: column-reverse; 
	}
	#home .logo-mobile {
		display: block;
	}
	#home .logo {
		display: none;
	}
	// #about .wall .frame {
	//     width: 220px; height: 285px;
	// }
	// #about .wall .frame.frame-3 {
	// 	display: none;
	// }
	// #about .wall .frame.frame-2 {
	// 	width: 220px; height: 285px; margin-top: 30px;
	// }

	#about .wall {
		width: 100%; grid-template-columns: repeat(1, auto);
		.left {
			display: none;
		}
		.right {
			width: 100%; height: auto;
			.frame.frame-2 {
				display: none;
				transform: none !important; margin-bottom: 30px;
				width: 100%; padding-top: 100%; margin-top: 30px; 
				border: 11px solid #fff; background-size: cover; margin-left: 0; 
				height: 0px;
			}
			.frame.frame-3 {
				display: none;
			}
		}

	}



	#about .biography {
		position: relative; z-index: 5000; padding-right: 0px;
	}
	#clients .clients-list {
		grid-template-columns: repeat(4, auto);
	}
	#clients .clients-list .client:nth-child(n + 13) {
	  display: none;
	}
	#projects .projects-wrapper .project {
		width: 100vw; flex:0 0 100vw;
	}
	#project-details .project-wrapper .project-description {
		flex-direction: column;
	}
	#project-details .project-wrapper .project-description .row,
	#project-details .project-wrapper .project-description .row.last {
		padding: 25px;
	}
	#project-details .btn-next,
	#project-details .btn-previous{
		width: 30px; height: 30px; top: calc(50% - 15px);
		background-size: 15px; background-position: center;
		&:hover {
			background-position: center;
		}
	}
	#clients .clients-list .client {
		background-size: contain;
	}
}

@media screen and (max-width: 420px) {
	#clients .clients-list {
		grid-template-columns: repeat(2, auto);
	}
	#clients .clients-list .client:nth-child(n + 13) {
	  display: none;
	}
	#about {
		padding: 20px 15px;
	}
	.content {
		padding: 0px 20px;
	}
}